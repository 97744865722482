import React from 'react'

import './SolutionPage.css'
import MapShapes from './MapShapes'
import { withShapesMap, withAppData } from '../hoc'
import SolutionPage from './SolutionPage'
import { classes as mapInfoBoxClasses } from './MapInfoBox'
import iconInfoBoxCalendar from '../assets/_svg/_ui-interface/_map/_tooltip-map/icon-tooltip-calendar-blue.svg'
import iconInfoBoxInfo from '../assets/_svg/_ui-interface/_map/_tooltip-map/icon-tooltip-info-yellow.svg'


export const TAB_NAME = 'encombrant'

class SolutionEncombrant extends React.Component {

    renderOverlayContent() {
        if (!this.props.mapSelectedShape) {
            return null
        }

        return (
            <div>
                <div {...mapInfoBoxClasses('info1')}>
                    {this.props.mapSelectedShape.sector}
                </div>
                <div {...mapInfoBoxClasses('info2')}>
                    <img 
                        {...mapInfoBoxClasses('info-icon')} 
                        src={iconInfoBoxInfo}
                        alt=""
                    />
                    Encombrants à déposer la veille à partir de 19h sur le trottoir
                </div>
                <div {...mapInfoBoxClasses('info3')}>
                    <img 
                        {...mapInfoBoxClasses('info-icon')} 
                        src={iconInfoBoxCalendar} 
                        alt=""
                    />
                    Les collectes sont également assurées les jours fériés
                </div>
            </div>
        )
    }

    render() {
        return (
            <SolutionPage
                solutionData={this.props.solutionData}
            >
                <MapShapes
                    shapes={this.props.mapShapes} 
                    selectedShapeIndex={this.props.mapSelectedShapeIndex}
                    infoBoxContent={this.renderOverlayContent()}
                    solutionData={this.props.solutionData}
                />
            </SolutionPage>
        );
    }
}
    
export default withAppData(
    withShapesMap(SolutionEncombrant, TAB_NAME),
    [TAB_NAME]
)
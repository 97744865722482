import React from 'react'
import BEMHelper from 'react-bem-helper'

import SolutionPage from './SolutionPage/SolutionPage'
import RawHtml from './RawHtml'
import config from './config'
import { withAppData } from './hoc'
import SolutionBox from './SolutionBox'
import './About.css'

const classes = new BEMHelper('About')

function About(props) {

    const textIntro = `Les collectivités locales de plus de 3 500 habitants ont l’obligation de publier, dans un format ouvert et exploitable, leurs principaux documents administratifs ainsi que tous les documents présentant un intérêt économique, social, sanitaire ou environnemental. Soucieuse d’avoir toujours un – voire deux – temps d’avance, la Ville d’Issy-les-Moulineaux a engagé sa démarche de transparence et d’open data (données sur des informations d'intérêt général accessibles à tous) en 2012.</br></br>La singularité de la démarche d’Issy tient dans la volonté de démocratiser l’open data. En effet, en l’état, les fichiers « bruts » sont illisibles pour la plupart des non spécialistes. Pour les rendre accessibles au plus grand nombre, le portail open data a été éditorialisé et scénarisé à l’aide de cartes, graphiques, chiffres clés, galeries photos et vidéos.</br></br>Dans ce contexte, Issy a créé, en collaboration avec les spécialistes de <a href=${config.WEDODATA_URL} target="_blank">WEDODATA</a>, cette visualisation pour permettre à tous de profiter des différents jeux de données mis en open data depuis plusieurs années et faciliter l’accès aux informations sur le tri des déchets.</br></br>Vous recherchez la localisation du conteneur en verre le plus proche de chez vous ou le jour de passage des encombrants ? Vous avez parfois des doutes quant aux bons gestes à adopter ? Découvrez toutes les réponses à vos questions en quelques clics grâce à cette visualisation synthétique.</br></br>Et si vous vous sentez l’âme d’un geek et que vous avez envie de « jouer » avec nos données, rendez-vous sur <a href="${config.ISSY_DATA_URL}" target="_blank">data.issy.com</a>.`

    const solutionBoxes = props.appData.solution.map((solutionData) => {
        return (
            <SolutionBox 
                surtitle="À propos"
                title={solutionData.title}
                solutionName={solutionData.name}
                footerText="Plus d'infos"
                key={solutionData.name}
            >
                <RawHtml html={solutionData.textIntro} />
                <h2>Le saviez-vous ?</h2>
                <RawHtml html={solutionData.didYouKnow} />
            </SolutionBox>
        )
    })

    return (
        <SolutionPage
            solutionData={({
                title: 'À propos',
                name: 'objets',
                textIntro
            })}
            {...classes()}
        >
            {solutionBoxes}
        </SolutionPage>
    )
}

export default withAppData(About, ['solution'])
import React from 'react'
import BEMHelper from 'react-bem-helper'

import './Loader.css'

const classes = new BEMHelper('Loader')


function Loader() {
    return (
        <div {...classes()}>
            <div {...classes('spinner')}>Loading...</div>
        </div>
    )
}

export default Loader

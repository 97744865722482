import { put, takeEvery, all } from 'redux-saga/effects'

import { appDataFetchError, appDataFetchSuccess } from '../actions'
import { buildAppDataSheetUrl, fetchJSON, convertDataV3ToV4 } from '../../utils' 
import PAGE_CONFIGS from '../../page-configs'

const fetchData = async (sheetName) =>
    Promise.all(PAGE_CONFIGS[sheetName].dataUrls.map(fetchJSON))

function* requestAppDataSingleSheet(sheetName) {
    const sheetId = PAGE_CONFIGS[sheetName].gSheetIndex
    if (sheetId === undefined) {
        return put(appDataFetchError(sheetName, new Error(`Unknown sheet name ${sheetName}`)))
    }

    const dataCleaner = PAGE_CONFIGS[sheetName].cleanData
    try {
        if (sheetName === 'bac_jaune' || sheetName === 'encombrant' || sheetName === 'bac_gris' || sheetName === 'verre' 
        || sheetName === 'textile' || sheetName === 'decheterie' || sheetName === 'sapin' || sheetName === 'dangereux' || sheetName === 'electromenager') {
            const dataSets = yield fetchData(sheetName)
            const cleanData = yield dataCleaner(...dataSets)
            yield put(appDataFetchSuccess(sheetName, cleanData))
        } else {
            const data = yield fetchJSON(buildAppDataSheetUrl(sheetId))
            const convertedData = convertDataV3ToV4(data)
            const cleanData = dataCleaner(convertedData.feed.entry)
            yield put(appDataFetchSuccess(sheetName, cleanData))
        }

    } catch(error) {
        yield put(appDataFetchError(sheetName, error))
    }
}

function* requestAppData(action) {
    yield all(action.payload.sheetNames.map(requestAppDataSingleSheet))
}

export function* appDataFetchRequestSaga() {
    yield takeEvery('appDataFetchRequest', requestAppData)
}

import React from 'react'
import BEMHelper from 'react-bem-helper'
import { Link } from 'react-router-dom'

import './SolutionPage.css'
import './SolutionPage-pages.css'
import Header from '../Header'
import config from '../config'
import Footer from '../Footer/Footer'
import PAGE_CONFIGS from '../page-configs'
import UnderlinedWord from '../UnderlinedWord'
import RawHtml from '../RawHtml'

const classes = new BEMHelper('SolutionPage')

class SolutionPage extends React.Component {

    constructor(props) {
        super(props)
        this.state = { mounted: false }
    }

    componentDidMount() {
        setTimeout(() => this.setState({ mounted: true }), 50)
    }

    renderTitle() {
        const [last, beforeLast, ...remainer] = this.props.solutionData.title.split(' ').reverse()
        const title = [
            remainer.length ? remainer.reverse().join(' ') : null,
            remainer.length ? <br key='br' /> : null,
            <UnderlinedWord 
                key='underlined' 
                text={`${beforeLast ? beforeLast + ' ' : ''}${last}`}
            />,
        ]

        return (
            <div { ...classes('title') }>
                <h1>{ title }</h1>
                <img src={ PAGE_CONFIGS[this.props.solutionData.name].bigIcon } alt={this.props.solutionData.title} />
            </div>
        )
    }

    renderDidYouKnow() {
        if (!this.props.solutionData.didYouKnow) {
            return null
        }
        return (
            <div {...classes('did-you-know')}>
                <h2>Le saviez-vous ?</h2>
                <div>
                    <RawHtml html={ this.props.solutionData.didYouKnow } />
                </div>
            </div>
        )
    }
    
    renderMoreInfo() {
        if (!this.props.solutionData.urlMoreInfo) {
            return null
        }
        return (
            <a 
                {...classes('more-info', null, 'button button--blue')}
                href={ this.props.solutionData.urlMoreInfo } 
                target="_blank"
                rel="noopener noreferrer"
            >
                Plus d'infos
            </a>
        )
    }

    renderFooter() {
        if (this.props.hasFooter) {
            return <Footer />
        }
    }

    render() {
        return (
            <div {...classes(
                null, 
                [this.state.mounted ? 'animation-start': null, this.props.solutionData.name], 
                this.props.className
            )}>
                <Header />

                <div {...classes('body')}>
                    <div {...classes('left')}>
                        <div>
                            <Link 
                                { ...classes('to-home', null, 'button button--blue') }
                                to={config.ROOT_URL}
                            >
                                ← retour
                            </Link>
                        </div>
                        {this.renderTitle()}
                        <div { ...classes('info') }>
                            <RawHtml html={ this.props.solutionData.textIntro } />
                            {this.renderDidYouKnow()}
                            {this.renderMoreInfo()}
                        </div>
                    </div>
                    
                    <div {...classes('right')}>
                        {this.props.children}
                    </div>       
                </div>   

                {this.renderFooter()}
            </div>
        )
    }
}

export default SolutionPage
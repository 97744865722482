import React from 'react'
import { withRouter } from 'react-router-dom'
import BEMHelper from 'react-bem-helper'

import { withAppData } from '../hoc'
import SolutionPage from './SolutionPage'
import SolutionBox from '../SolutionBox'
import RawHtml from '../RawHtml'

const classes = new BEMHelper('SolutionObjets')
export const TAB_NAME = 'objets'

class SolutionObjets extends React.Component {

    render() {
        const { itemName } = this.props.match.params
        const item = this.props.appData.objets.filter((item) => item.name === itemName)[0]
        const solutions = ([item.solution1, item.solution2]).filter((s) => s)

        const solutionElems = solutions.map((solutionName, i) => {
            const solutionData = this.props.appData.solution
                .filter((solutionData) => solutionData.name === solutionName)[0]
            if (!solutionData) {
                return null
            }

            const boxText = <RawHtml html={solutionData.boxText} />
            return (
                <SolutionBox 
                    key={i}
                    surtitle={`Solution #${i + 1}`}
                    title={solutionData.title}
                    subtitle={boxText}
                    solutionName={solutionData.name}
                    footerText={solutionData.callToAction}
                />
            )
        })

        const textIntro = (
            `<b key='count'>${solutions.length} solution${solutions.length ? 's': ''}</b> `
            + 'trouvées pour ce type de déchets.'
        )

        return (
            <SolutionPage
                solutionData={({
                    title: itemName,
                    name: TAB_NAME,
                    textIntro
                })}
                hasFooter={true}
                {...classes()}
            >
                {solutionElems}
            </SolutionPage>
        );
    }
}
    
const SolutionObjetsWithRouter = withRouter(SolutionObjets)
export default withAppData(
    SolutionObjetsWithRouter,
    [TAB_NAME, 'solution']
)
import React from 'react'

function RawHtml(props) {
    return (
        <div dangerouslySetInnerHTML={{__html: props.html}} />
    )
}

export default RawHtml


import React from 'react'
import leaflet from 'leaflet'

import MapPins from './MapPins'
import { withPinsMap, withAppData } from '../hoc'
import SolutionPage from './SolutionPage'
import { classes as mapInfoBoxClasses } from './MapInfoBox'

import iconInfoBoxLocation from '../assets/_svg/_ui-interface/_map/_tooltip-map/icon-tooltip-location-yellow.svg'
import iconSelectedPin from '../assets/_svg/_ui-interface/_map/icon-map_marker-big-off-brown.svg'
import iconPin from '../assets/_svg/_ui-interface/_map/icon-map_marker-big-off-green.svg'


export const TAB_NAME = 'sapin'


class SolutionSapin extends React.Component {

    renderOverlayContent() {
        if (!this.props.mapSelectedPin) {
            return null
        }

        return (
            <div>
                <div {...mapInfoBoxClasses('info1')}>
                    {this.props.mapSelectedPin.location}
                </div>
                {
                    this.props.mapSelectedPin.locationDetails &&
                    <div {...mapInfoBoxClasses('info2')}>
                        <img 
                            {...mapInfoBoxClasses('info-icon')} 
                            src={iconInfoBoxLocation} 
                            alt=""
                        />
                        {this.props.mapSelectedPin.locationDetails}
                    </div>
                }
            </div>
        )
    }

    getPins() {
        if (!this.props.mapPins) {
            return
        }
        return this.props.mapPins.map((pin) => ({
            ...pin,
            icon: leaflet.icon({
                iconUrl: iconPin,
            }),
            selectedIcon: leaflet.icon({
                iconUrl: iconSelectedPin,
            })
        }))
    }

    render() {
        return (
            <SolutionPage
                solutionData={this.props.solutionData}
            >
                <MapPins
                    pins={this.getPins()} 
                    selectedPinIndex={this.props.mapSelectedPinIndex}
                    infoBoxContent={this.renderOverlayContent()}
                    solutionData={this.props.solutionData}
                />
            </SolutionPage>
        );
    }
}
    
export default withAppData(
    withPinsMap(SolutionSapin, TAB_NAME),
    [TAB_NAME]
)
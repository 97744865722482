import React from 'react'
import { createBrowserHistory } from 'history'
import BEMHelper from 'react-bem-helper'
import {
    Router,
    Switch,
    Route
} from 'react-router-dom'
import { historyChanged, cookiesSetAnalyticsId, Cookies } from 'issy-components'
import { connect } from 'react-redux'

import config from './config'
import { withAppData } from './hoc'
import Home from './Home/Home'
import SolutionBacJaune, { TAB_NAME as TAB_NAME_BAC_JAUNE } from './SolutionPage/SolutionBacJaune'
import SolutionBacGris, { TAB_NAME as TAB_NAME_BAC_GRIS } from './SolutionPage/SolutionBacGris'
import SolutionTextile, { TAB_NAME as TAB_NAME_TEXTILE } from './SolutionPage/SolutionTextile'
import SolutionDecheterie, { TAB_NAME as TAB_NAME_DECHETERIE } from './SolutionPage/SolutionDecheterie'
import SolutionEncombrant, { TAB_NAME as TAB_NAME_ENCOMBRANT } from './SolutionPage/SolutionEncombrant'
import SolutionVerre, { TAB_NAME as TAB_NAME_VERRE } from './SolutionPage/SolutionVerre'
import './App.css'
import SolutionObjets, { TAB_NAME as TAB_NAME_OBJETS } from './SolutionPage/SolutionObjets'
import SolutionElectromenager, { TAB_NAME as TAB_NAME_ELECTROMENAGER } from './SolutionPage/SolutionElectromenager'
import SolutionDangereux, { TAB_NAME as TAB_NAME_DANGEREUX } from './SolutionPage/SolutionDangereux'
import SolutionSapin, { TAB_NAME as TAB_NAME_SAPIN } from './SolutionPage/SolutionSapin'
import About from './About'

const classes = new BEMHelper('App')
const history = createBrowserHistory()


class App extends React.Component {

    constructor(props) {
        super(props)
        const solutionsData = {}
        this.props.appData.solution.forEach(
            (solutionData) => solutionsData[solutionData.name] = solutionData)

        this.solutionsRoutes = [
            (
                <Route 
                    path={`${config.ROOT_URL}/${TAB_NAME_BAC_JAUNE}`} 
                    key={TAB_NAME_BAC_JAUNE}
                >
                    <SolutionBacJaune 
                        solutionData={solutionsData[TAB_NAME_BAC_JAUNE]} 
                    />
                </Route>
            ),
            (
                <Route 
                    path={`${config.ROOT_URL}/${TAB_NAME_BAC_GRIS}`} 
                    key={TAB_NAME_BAC_GRIS}
                >
                    <SolutionBacGris 
                        solutionData={solutionsData[TAB_NAME_BAC_GRIS]} 
                    />
                </Route>
            ),
            (
                <Route 
                    path={`${config.ROOT_URL}/${TAB_NAME_DECHETERIE}`} 
                    key={TAB_NAME_DECHETERIE}
                >
                    <SolutionDecheterie 
                        solutionData={solutionsData[TAB_NAME_DECHETERIE]} 
                    />
                </Route>
            ),
            (
                <Route 
                    path={`${config.ROOT_URL}/${TAB_NAME_ENCOMBRANT}`} 
                    key={TAB_NAME_ENCOMBRANT}
                >
                    <SolutionEncombrant 
                        solutionData={solutionsData[TAB_NAME_ENCOMBRANT]} 
                    />
                </Route>
            ),
            (
                <Route 
                    path={`${config.ROOT_URL}/${TAB_NAME_VERRE}`} 
                    key={TAB_NAME_VERRE}
                >
                    <SolutionVerre 
                        solutionData={solutionsData[TAB_NAME_VERRE]} 
                    />
                </Route>
            ),
            (
                <Route 
                    path={`${config.ROOT_URL}/${TAB_NAME_ELECTROMENAGER}`} 
                    key={TAB_NAME_ELECTROMENAGER}
                >
                    <SolutionElectromenager 
                        solutionData={solutionsData[TAB_NAME_ELECTROMENAGER]} 
                    />
                </Route>
            ),
            (
                <Route 
                    path={`${config.ROOT_URL}/${TAB_NAME_TEXTILE}`} 
                    key={TAB_NAME_TEXTILE}
                >
                    <SolutionTextile
                        solutionData={solutionsData[TAB_NAME_TEXTILE]} 
                    />
                </Route>
            ),
            (
                <Route 
                    path={`${config.ROOT_URL}/${TAB_NAME_DANGEREUX}`} 
                    key={TAB_NAME_DANGEREUX}
                >
                    <SolutionDangereux
                        solutionData={solutionsData[TAB_NAME_DANGEREUX]} 
                    />
                </Route>
            ),
            (
                <Route 
                    path={`${config.ROOT_URL}/${TAB_NAME_SAPIN}`} 
                    key={TAB_NAME_SAPIN}
                >
                    <SolutionSapin
                        solutionData={solutionsData[TAB_NAME_SAPIN]} 
                    />
                </Route>
            ),
        ]
    }

    componentDidMount() {
        history.listen((location) => this.props.historyChanged(location))
        this.props.cookiesSetAnalyticsId(config.GOOGLE_ANALYTICS_ID)
    }

    render (){
        return (
            <div { ...classes() }>
                <Router history={history}>
                    <Switch>
                        { this.solutionsRoutes }
                        <Route path={`${config.ROOT_URL}/${TAB_NAME_OBJETS}/:itemName`}>
                            <SolutionObjets />
                        </Route>
                        <Route path={`${config.ROOT_URL}/about`}>
                            <About />
                        </Route>
                        <Route path={`/${config.ROOT_URL}`}>
                            <Home />
                        </Route>
                    </Switch>
                </Router>
                <Cookies />
            </div>
        )
    }
}

const ConnectedApp = connect(
    undefined, 
    {
        historyChanged,
        cookiesSetAnalyticsId
    }
)(App)

export default withAppData(ConnectedApp, ['solution'])
    
import React from 'react';
import { connect } from 'react-redux'
import BEMHelper from 'react-bem-helper'
import { Link } from 'react-router-dom'

import { ReactComponent as ImageFacebook } from './assets/_svg/_ui-interface/_social-media/icon-facebook.svg'
import { ReactComponent as ImageTwitter } from './assets/_svg/_ui-interface/_social-media/icon-twitter.svg'


import './Menu.css';
import LocalizeMe from './LocalizeMe'
import config from './config'

const classes = new BEMHelper('Menu')


function Menu(props) {
    const encodedUrl = encodeURIComponent(config.SITE_URL)
    const encodedShareTweet = encodeURIComponent(config.TWEET_SHARE_TEXT)
    return (
        <div {...classes()}>
            <div {...classes('container')}>
                <span {...classes('share-label')}>Partager</span>
                <ul>
                    {/* Buttons generated with https://sharingbuttons.io/ */}
                    <li {...classes('share-button')}>
                        <a className="resp-sharing-button__link" href={`https://facebook.com/sharer/sharer.php?u=${encodedUrl}`} target="_blank" rel="noopener noreferrer" aria-label="">
                            <ImageFacebook />
                        </a>
                    </li>
                    <li {...classes('share-button')}>
                        <a className="resp-sharing-button__link" href={`https://twitter.com/intent/tweet/?text=${encodedShareTweet}&amp;url=${encodedUrl}`} target="_blank" rel="noopener noreferrer" aria-label="">
                            <ImageTwitter />
                        </a>
                    </li>
                    <li>
                        <Link 
                            to={`${config.ROOT_URL}/about`}
                            className="link link--menu"
                        >
                            À propos
                        </Link>
                    </li>
                    <li {...classes('localize-me', props.isLocalized ? 'localized': null)}>
                        <LocalizeMe>
                            <span className="link link--menu">
                                {
                                    props.isLocalized ? 'Localisé': 'Localisez-moi'
                                }
                            </span>
                        </LocalizeMe>
                    </li>
                </ul>
            </div>
        </div>
    );
}

export default connect(
    (state) => ({
        isLocalized: !!state.geoLocation.point
    })
)(Menu)
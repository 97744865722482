export default {
    ROOT_URL: '',
    SITE_URL: 'https://trier.issy.com/',
    TWEET_SHARE_TEXT: 'Que faire de ses déchets ?\nLes infos pratiques sur trier.issy.com\n#DataIssy #LaDataNotreDada',
    ISSY_DATA_URL: 'https://data.issy.com/',
    WEDODATA_URL: 'https://wedodata.fr',
    QUE_FAIRE_A_ISSY_URL: 'https://sortir.issy.com/agenda/?oaq%5Bwhat%5D=collecte+solidaire#agenda',
    APP_DATA_GSHEET_ID: '142dtzxT-s-mRXjHfl2hs7EOCqFcnkeqvXZrNKyyKHGg',
    GOOGLE_API_KEY: 'AIzaSyDgstkPPHTfWo5BWYN5mF69CtcRaHpgPhs',

    AUTOCOMPLETE_ITEMS_MAX_RESULTS: 10,
    AUTOCOMPLETE_ADDRESS_MAX_RESULTS: 5,
    AUTOCOMPLETE_DEBOUNCE_TIME: 500,

    MAP_TILE_LAYER: 'https://cartodb-basemaps-{s}.global.ssl.fastly.net/light_all/{z}/{x}/{y}.png',
    // Ref : https://wiki.openstreetmap.org/wiki/Tile_servers
    MAP_ATTRIBUTION: 'Map tiles by Carto, under CC BY 3.0. Data by OpenStreetMap, under ODbL.',
    MAP_SECTOR_BASE_STYLE: {
        color: 'white',
        fillColor: '#1c6bb3', // colorOcean
        fillOpacity: 0.6,
        weight: 1,
    },
    MAP_SECTOR_SELECTED_STYLE: {
        fillColor: '#ff801f', // colorLocation
    },
    MAP_SECTOR_OVER_STYLE: {
        fillColor: '#0f2942' // colorNightBlue
    },
    MAP_INITIAL_ZOOM: 13,
    MAP_MAX_ZOOM: 18,
    MAP_MIN_ZOOM: 12,
    MAP_BOUNDS: [[48.73356564679569, 2.1069717407226567], [48.893490333145365, 2.424888610839844]],
    MAP_FIT_BOUNDS_PADDING: [50, 50],

    TABLET_WIDTH_UPPER: 1280,
    PHABLET_WIDTH_UPPER: 1024,
    MOBILE_WIDTH_UPPER: 640,
    NARROW_SCREEN_UPPER_1: 720,
    NARROW_SCREEN_UPPER_2: 580,

    HOME_DESKTOP_TABLET_SOLUTION_PER_ROW: 5,

    GOOGLE_ANALYTICS_ID: 'UA-51867236-9'
}
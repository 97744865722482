import React from 'react'
import withSizes from 'react-sizes'
import leaflet from 'leaflet'
import BEMHelper from 'react-bem-helper'
import { connect } from 'react-redux'

import defaultMyLocationImg from '../assets/_svg/_ui-interface/_map/icon-location_me.svg'
import { getMostRecentUserPosition } from '../redux/selectors'
import { mapSetZoom } from '../redux/actions'
import config from '../config'
import MapFooter from './MapFooter'
import MapInfoBox from './MapInfoBox'
import MapLocalizationTools from './MapLocalizationTools'
import './Map.css'
const defaultMyLocationIcon = leaflet.icon({
    iconUrl: defaultMyLocationImg,
    iconAnchor: [20, 20]
})

const classes = new BEMHelper('Map')

class Map extends React.Component {

    constructor(props) {
        super(props)
        this.mapRef = React.createRef()
        this.leafletMap = null
        this.myLocationLeafletPin = null
        this.state = { height: 0 }
    }

    componentDidMount() {
        this.leafletMap = leaflet
            .map(this.mapRef.current, { 
                zoomControl: false,
                minZoom: config.MAP_MIN_ZOOM,
                maxZoom: config.MAP_MAX_ZOOM,
                maxBounds: config.MAP_BOUNDS,
            })
            .setView([48.812, 2.2802], this.props.zoom)
            .on('zoomend', this.onMapZoomEnd)

        leaflet.tileLayer(config.MAP_TILE_LAYER, {
            attribution: config.MAP_ATTRIBUTION
        })
            .addTo(this.leafletMap)

        this.updateMyLocationPin()

        if (this.props.onLeafletMapCreated) {
            this.props.onLeafletMapCreated(this.leafletMap)
            this.updateHeight()
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props.zoom !== prevProps.zoom) {
            this.leafletMap.setZoom(this.props.zoom)
        }

        if (this.props.myLocationPin !== prevProps.myLocationPin) {
            this.updateMyLocationPin()
        }

        // Also update map size when width change in case the width change liberates some
        // space for the map. 
        if (this.props.windowHeight !== prevProps.windowHeight
             || this.props.windowWidth !== prevProps.windowWidth) {
            this.updateHeight()
        }

        if (this.state.height !== prevState.height) {
            this.leafletMap.invalidateSize()
        }
    }

    updateHeight() {
        if (!this.mapRef.current || !this.leafletMap) {
            return
        }
        let height
        if (this.props.isPhablet) {
            const w = Math.max(document.documentElement.clientWidth, window.innerWidth || 0);
            height = w * 0.8
        } else {
            const rect = this.mapRef.current.getBoundingClientRect()
            const h = Math.max(document.documentElement.clientHeight, window.innerHeight || 0);
            height = h - rect.top
        }
        this.setState({ height })
    }

    updateMyLocationPin() {
        if (this.myLocationLeafletPin) {
            this.leafletMap.removeLayer(this.myLocationLeafletPin)
        }

        if (!this.props.myLocationPin) {
            return 
        }
        this.myLocationLeafletPin = leaflet.marker(this.props.myLocationPin.point, {
            icon: this.props.myLocationPin.icon || defaultMyLocationIcon
        }).addTo(this.leafletMap)
        this.myLocationLeafletPin.bindTooltip('vous', {
            permanent: true,
            direction: 'bottom',
            className: 'my-location-tooltip'
        }).openTooltip()
    }

    onMapZoomEnd = () => {
        this.props.mapSetZoom(this.leafletMap.getZoom())
    }

    render() {
        const style = { height: this.state.height }
        const mapInfoBox = (
            <MapInfoBox 
                infoBoxContent={this.props.infoBoxContent}
                canBeCollapsed={this.props.canInfoBoxBeCollapsed}
            />
        )
        const legendELem = this.props.legendContent ? (
            <div { ...classes('legend') }>
                {this.props.legendContent}
            </div>
        ) : null

        return (
            <div { ...classes() }>
                <MapLocalizationTools locateForText={this.props.solutionData.mapLocateFor} />
                { this.props.isMobile ? mapInfoBox : null }
                <div { ...classes('map-container') }>
                    {legendELem}
                    <div ref={this.mapRef} style={style} ></div>
                    <div { ...classes('footer-container') }>
                        { this.props.isMobile ? null : mapInfoBox }
                        <MapFooter
                            urlSeeData={this.props.solutionData.urlSeeData} 
                        />
                    </div>
                </div>
            </div>
        );
    }
}
    
const MapWithSizes = withSizes(({ height, width }) => {
    return {
        windowHeight: height,
        windowWidth: width,
        isMobile: width < config.MOBILE_WIDTH_UPPER,
        isPhablet: width < config.PHABLET_WIDTH_UPPER,
    }
})(Map)

export default connect(
    (state) => {
        const userPosition = getMostRecentUserPosition(state)
        return {
            zoom: state.map.zoom,
            myLocationPin: userPosition ? { point: userPosition }: null
        }
    },
    { mapSetZoom }
)(MapWithSizes)
import { createStore, applyMiddleware, compose } from 'redux'
import createSagaMiddleware from 'redux-saga'

import rootReducer from './reducers';
import rootSaga from './sagas'

const sagaMiddleware = createSagaMiddleware()
const middlewares = [applyMiddleware(sagaMiddleware)]
if (process.env.NODE_ENV === 'development') {
    // middlewares.push(window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__())
}

export default createStore(
    rootReducer,
    compose(...middlewares)
);
sagaMiddleware.run(rootSaga)

import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import BEMHelper from 'react-bem-helper'

import './FooterSearchBar.css'
import './shared.css'
import SearchField from '../SearchField'
import { searchString } from '../utils'
import config from '../config'
import { TAB_NAME as TAB_NAME_OBJETS } from '../SolutionPage/SolutionObjets'

export const classes = new BEMHelper('FooterSearchBar')


class FooterSearchBar extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            searchResults: null
        }
    }

    onInputChange = (value) => {
        if (value && value.length >= 2) {
            const searchResults = searchString(
                this.props.itemsData, 
                'name', 
                value, 
                config.AUTOCOMPLETE_ITEMS_MAX_RESULTS
            )
            this.setState({ searchResults })
        } else {
            this.setState({ searchResults: null })
        }
    }

    renderSearchResults() {
        if (!this.state.searchResults) {
            return null
        }

        const searchResultsElems = this.state.searchResults.map((searchResult) => {
            const itemName = searchResult.item.name
            const match = searchResult.match
            const onClick = () => {
                this.props.history.push(`${config.ROOT_URL}/${TAB_NAME_OBJETS}/${itemName}`)
                this.setState({ searchResults: null })
            }
            return (
                <div key={itemName} onClick={onClick} {...classes('results-item')}>
                    {match.preMatch}<b>{match.match}</b>{match.postMatch}
                </div>
            )
        })

        return (
            <div {...classes('results')}>
                {searchResultsElems}
            </div>
        )
    }

    hasNoResult() {
        return this.state.searchResults && this.state.searchResults.length === 0
    }

    render() {
        let labelText = 'Chercher directement un objet'
        if (this.state.searchResults) {
            labelText = `${this.state.searchResults.length} résultat${this.state.searchResults.length ? "s": ""}`
        }
        return (
            <div {...classes(
                null, 
                this.hasNoResult() ? 'no-result' : null, 
                this.props.className
            )}>
                {this.renderSearchResults()}
                <label>{labelText}</label>
                <SearchField 
                    placeholder="Verre, canapé, textiles..." 
                    onInputChange={this.onInputChange}
                    {...classes('search-field')}
                />
            </div>
        )
    }
}


export const InnerFooterSearchBar = FooterSearchBar

const FooterSearchBarWithRouter = withRouter(FooterSearchBar)
export default connect(
    (state) => ({
        itemsData: state.appData.objets.data
    }),
)(FooterSearchBarWithRouter)
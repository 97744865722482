import React from 'react';
import BEMHelper from 'react-bem-helper';

import './SearchField.css';
import { ReactComponent as SearchImage } from './assets/_svg/_ui-interface/icon-search.svg'
import { ReactComponent as ResetImage } from './assets/_svg/_ui-interface/icon-times.svg'

export const classes = new BEMHelper('SearchField')

class SearchField extends React.Component {

    constructor(props) {
        super(props);
        this.inputRef = React.createRef();
        this.state = {
            inputValue: ''
        }
    }

    componentDidMount() {
        if (this.props.initialValue) {
            this.setState({ inputValue: this.props.initialValue })
        }
    }

    componentDidUpdate(prevProps) {
        if (this.props.initialValue !== prevProps.initialValue) {
            this.setState({ inputValue: this.props.initialValue })
        }
    }

    onButtonClick = () => {
        if (this.hasContent()) {
            this.setState({ inputValue: '' })
            if (this.props.onInputChange) {
                this.props.onInputChange('')
            }

        } else {
            this.inputRef.current.focus()
        }
    }

    onInputChange = (event) => {
        if (this.props.onInputChange) {
            this.props.onInputChange(event.target.value)
        }
        this.setState({
            inputValue: event.target.value
        })
    }

    render() {
        return (
            <div { ...classes(
                null, 
                this.hasContent() ? 'has-content' : 'empty', 
                this.props.className
            ) }>
                <input 
                    value={this.state.inputValue} 
                    ref={this.inputRef} 
                    onChange={this.onInputChange}
                    placeholder={this.props.placeholder}
                />
                <span { ...classes('button') } onClick={this.onButtonClick}>
                    <SearchImage {...classes('search-image')} />
                    <ResetImage {...classes('reset-image')} />
                </span>
            </div>
        );
    }

    hasContent() { return this.state.inputValue.length > 0 }
}
    
export default SearchField;
    
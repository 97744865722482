import React from 'react'
import BEMHelper from 'react-bem-helper'
import { Link } from 'react-router-dom'

import './Header.css'
import logo from './assets/_svg/_logos/logo-issy-blackfont.svg'
import Menu from './Menu'
import config from './config'

const classes = new BEMHelper('Header')

function Header() {
    return (
        <div {...classes()}>
            <div {...classes('container')}>
                <div {...classes('logo-container')}>
                    <Link to={config.ROOT_URL}>
                        <img {...classes('logo-container')} src={logo} alt="logo" />
                    </Link>
                </div>
                <Menu />
            </div>
        </div>
    )
}

export default Header
import { combineReducers } from 'redux'
import { issyComponentsReducers } from 'issy-components'

import { REQUEST_STATUSES } from '../constants'
import config from '../config'

const GEO_LOCATION_INITIAL_STATE = {
    status: REQUEST_STATUSES.INITIAL,
    timestamp: 0,
    point: null
}

const APP_DATA_SHEET_INITIAL_STATE = {
    status: REQUEST_STATUSES.INITIAL,
    data: null
}
const APP_DATA_INITIAL_STATE = {}

const ADDRESS_QUERY_INITIAL_STATE = {
    status: REQUEST_STATUSES.INITIAL,
    timestamp: 0,
    point: null,
    address: null,
    results: null,
}

const MAP_INITIAL_STATE = {
    zoom: config.MAP_INITIAL_ZOOM,
    pins: null,
    selectedPinIndex: null,
    shapes: null,
    selectedShapeIndex: null,
    resetRequested: false,
}

const appDataSheetReducer = (state=APP_DATA_SHEET_INITIAL_STATE, action) => {
    switch (action.type) {
        case ('appDataFetchRequest'):
            return {
                ...state,
                status: REQUEST_STATUSES.PROGRESS
            }
        case ('appDataFetchSuccess'):
            return {
                data: action.payload.data,
                status: REQUEST_STATUSES.SUCCESS
            }
        case ('appDataFetchError'):
            return {
                ...state,
                status: REQUEST_STATUSES.FAILED
            }
        default:
            return state
    }
}

const appDataReducer = (state=APP_DATA_INITIAL_STATE, action) => {
    switch (action.type) {
        case ('appDataFetchRequest'):
            const newState = {}
            action.payload.sheetNames.forEach((sheetId) => {
                newState[sheetId] = appDataSheetReducer(state[sheetId], action)
            })
            return {
                ...state,
                ...newState
            }
        case ('appDataFetchSuccess'):
        case ('appDataFetchError'):
            return {
                ...state,
                [action.payload.sheetName]: appDataSheetReducer(state[action.payload.sheetName], action)
            }
        default:
            return state
    }
}

const geoLocationReducer = (state=GEO_LOCATION_INITIAL_STATE, action) => {
    switch (action.type) {
        case ('geoLocationRequest'):
            return {
                ...state,
                status: REQUEST_STATUSES.PROGRESS
            }
        case ('geoLocationSuccess'):
            return {
                point: action.payload.point,
                timestamp: Date.now(),
                status: REQUEST_STATUSES.SUCCESS,
            }
        case ('geoLocationError'):
            return {
                ...state,
                status: REQUEST_STATUSES.FAILED
            }
        case ('addressQuerySelectResult'):
            return GEO_LOCATION_INITIAL_STATE
        default:
            return state
    }
}

const addressQueryReducer = (state=ADDRESS_QUERY_INITIAL_STATE, action) => {
    switch (action.type) {
        case ('addressQueryRequest'):
            return {
                ...state,
                status: REQUEST_STATUSES.PROGRESS
            }
        case ('addressQuerySuccess'):
            return {
                ...state,
                results: action.payload.results,
                status: REQUEST_STATUSES.SUCCESS
            }
        case ('addressQueryError'):
            return {
                ...state,
                status: REQUEST_STATUSES.FAILED
            }
        case ('addressQuerySelectResult'):
            return {
                ...ADDRESS_QUERY_INITIAL_STATE,
                point: action.payload.point,
                address: action.payload.address
            }
        case ('addressQueryReset'):
        case ('geoLocationSuccess'):
        case ('mapSetSelectedPin'):
        case ('mapSetSelectedShape'):
            return ADDRESS_QUERY_INITIAL_STATE

        default:
            return state
    }
}

const mapReducer = (state=MAP_INITIAL_STATE, action) => {

    switch(action.type) {
        case ('mapZoomPlus'):
            return {
                ...state,
                zoom: Math.min(state.zoom + 1, config.MAP_MAX_ZOOM)
            }
        case ('mapZoomMinus'):
            return {
                ...state,
                zoom: Math.max(state.zoom - 1, config.MAP_MIN_ZOOM)
            }
        case ('mapSetZoom'):
            return {
                ...state,
                zoom: action.payload.zoom
            }
        case ('addressQuerySuccess'):
        case ('geoLocationSuccess'):
        case ('addressQueryReset'):
            return {
                ...state,
                selectedShapeIndex: null,
                selectedPinIndex: null
            }
        case ('mapSetSelectedShape'):
            return {
                ...state,
                selectedShapeIndex: action.payload.selectedShapeIndex
            }
        case ('mapSetSelectedPin'):
            return {
                ...state,
                selectedPinIndex: action.payload.selectedPinIndex
            }
        case ('mapSetPins'):
            return {
                ...state,
                pins: action.payload.pins,
                selectedPinIndex: null
            }
        case ('mapResetPins'):
            return {
                ...state,
                pins: null,
                selectedPinIndex: null
            }
        case ('mapSetShapes'):
            return {
                ...state,
                shapes: action.payload.shapes,
                selectedShapeIndex: null
            }
        case ('mapResetShapes'):
            return {
                ...state,
                shapes: null,
                selectedShapeIndex: null
            }
        case ('mapResetRequested'):
            return {
                ...state,
                resetRequested: true
            }
        case ('mapResetSuccess'):
            return {
                ...state,
                resetRequested: false
            }
        default:
            return state
    }
}

export default combineReducers({
    ...issyComponentsReducers,
    geoLocation: geoLocationReducer,
    appData: appDataReducer,
    addressQuery: addressQueryReducer,
    map: mapReducer,
})
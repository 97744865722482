import { put, takeEvery } from 'redux-saga/effects'

import { fetchJSON, buildAddressQueryUrl, cleanAddressQueryResults } from '../../utils'
import { addressQuerySuccess, addressQueryError } from '../actions'


function* requestAddressQuery(action) {
    const query = action.payload.query
    try {
        const data = yield fetchJSON(buildAddressQueryUrl(query))
        const queryResults = cleanAddressQueryResults(query, data)
        yield put(addressQuerySuccess(query, queryResults))
    } catch(error) {
        yield put(addressQueryError(query, error))
    }
}

export function* addressQueryRequestSaga() {
    yield takeEvery('addressQueryRequest', requestAddressQuery)
}
import React from 'react'

import './SolutionPage.css'
import MapShapes from './MapShapes'
import { classes as mapInfoBoxClasses } from './MapInfoBox'
import { withShapesMap, withAppData } from '../hoc'
import SolutionPage from './SolutionPage'

export const TAB_NAME = 'bac_gris'

class SolutionBacGris extends React.Component {

    renderOverlayContent() {
        if (!this.props.mapSelectedShape) {
            return null
        }

        return (
            <div>
                <div {...mapInfoBoxClasses('info1')}>
                    {this.props.mapSelectedShape.sector}
                </div>
            </div>
        )
    }

    render() {
        return (
            <SolutionPage
                solutionData={this.props.solutionData}
            >
                <MapShapes
                    solutionData={this.props.solutionData}
                    shapes={this.props.mapShapes} 
                    selectedShapeIndex={this.props.mapSelectedShapeIndex}
                    infoBoxContent={this.renderOverlayContent()}
                    canInfoBoxBeCollapsed={false}
                />
            </SolutionPage>
        );
    }
}
    
export default withAppData(
    withShapesMap(SolutionBacGris, TAB_NAME),
    [TAB_NAME]
)
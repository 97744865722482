import { normalizeGeoJsonShape, capitalizeString, normalizeGeoPoint2d } from './utils'

import decheterieSmallIcon from './assets/_svg/_icons/_small/icon-small-decheterie_160x160px.svg'
import electromenagerSmallIcon from './assets/_svg/_icons/_small/icon-small-electromenager_160x160px.svg'
import encombrantSmallIcon from './assets/_svg/_icons/_small/icon-small-encombrant_160x160px.svg'
import bacGrisSmallIcon from './assets/_svg/_icons/_small/icon-small-poubelle_grise_160x160px.svg'
import bacJauneSmallIcon from './assets/_svg/_icons/_small/icon-small-poubelle_jaune_160x160px.svg'
import sapinSmallIcon from './assets/_svg/_icons/_small/icon-small-sapin_160x160px.svg'
import toxiqueSmallIcon from './assets/_svg/_icons/_small/icon-small-toxique_160x160px.svg'
import verreSmallIcon from './assets/_svg/_icons/_small/icon-small-verre_160x160px.svg'
import vetementSmallIcon from './assets/_svg/_icons/_small/icon-small-vetement_160x160px.svg'

import objetsBigIcon from './assets/_svg/_icons/_others/icon-all_items.svg'
import decheterieBigIcon from './assets/_svg/_icons/_big/icon-big-decheterie_240x160px.svg'
import electromenagerBigIcon from './assets/_svg/_icons/_big/icon-big-electromenager_240x160px.svg'
import encombrantBigIcon from './assets/_svg/_icons/_big/icon-big-encombrant_240x160px.svg'
import bacGrisBigIcon from './assets/_svg/_icons/_big/icon-big-poubelle_grise_240x160px.svg'
import bacJauneBigIcon from './assets/_svg/_icons/_big/icon-big-poubelle_jaune_240x160px.svg'
import sapinBigIcon from './assets/_svg/_icons/_big/icon-big-sapin_240x160px.svg'
import toxiqueBigIcon from './assets/_svg/_icons/_big/icon-big-toxique_240x160px.svg'
import verreBigIcon from './assets/_svg/_icons/_big/icon-big-verre_240x160px.svg'
import vetementBigIcon from './assets/_svg/_icons/_big/icon-big-vetement_240x160px.svg'

import decheterieSolutionIcon from './assets/_svg/_icons/_solution/icon-solution-decheterie_260x130px.svg'
import electromenagerSolutionIcon from './assets/_svg/_icons/_solution/icon-solution-electromenager_260x130px.svg'
import encombrantSolutionIcon from './assets/_svg/_icons/_solution/icon-solution-encombrant_260x130px.svg'
import bacGrisSolutionIcon from './assets/_svg/_icons/_solution/icon-solution-poubelle_grise_260x130px.svg'
import bacJauneSolutionIcon from './assets/_svg/_icons/_solution/icon-solution-poubelle_jaune_260x130px.svg'
import sapinSolutionIcon from './assets/_svg/_icons/_solution/icon-solution-sapin_260x130px.svg'
import toxiqueSolutionIcon from './assets/_svg/_icons/_solution/icon-solution-toxique_260x130px.svg'
import verreSolutionIcon from './assets/_svg/_icons/_solution/icon-solution-verre_260x130px.svg'
import vetementSolutionIcon from './assets/_svg/_icons/_solution/icon-solution-vetement_260x130px.svg'


export default {
    bac_jaune: {
        gSheetIndex: 'solution1 bac_jaune',
        cleanData: (data) => {
            return data.map((row, i) => {
                return {
                    polygons: normalizeGeoJsonShape(row.geo_shape),
                    sector: row.secteur
                }
            })
        },
        smallIcon: bacJauneSmallIcon,
        bigIcon: bacJauneBigIcon,
        solutionIcon: bacJauneSolutionIcon,
        dataUrls: ['https://data.issy.com/api/v2/catalog/datasets/zones-de-collecte-de-la-collecte-selective/exports/json?limit=-1&pretty=false&timezone=Europe%2FParis']
    }, 

    encombrant: {
        gSheetIndex: 'solution2 encombrant',
        cleanData: (data) => {
            return data.map((row, i) => {
                return {
                    polygons: normalizeGeoJsonShape(row.geo_shape),
                    sector: row.secteur
                }
            })
        },
        smallIcon: encombrantSmallIcon,
        bigIcon: encombrantBigIcon,
        solutionIcon: encombrantSolutionIcon,
        dataUrls: ['https://data.issy.com/api/v2/catalog/datasets/gpso_prop_secteur_encombrant/exports/json?limit=-1&pretty=false&timezone=Europe%2FParis']
    },

    bac_gris: {
        gSheetIndex: 'solution3 bac_gris',
        cleanData: (data) => {
            return data.map((row, i) => {
                return {
                    polygons: normalizeGeoJsonShape(row.geo_shape),
                    sector: row.secteur
                }
            })
        },
        smallIcon: bacGrisSmallIcon,
        bigIcon: bacGrisBigIcon,
        solutionIcon: bacGrisSolutionIcon,
        dataUrls: ['https://data.issy.com/api/v2/catalog/datasets/secteur-de-la-collecte-des-ordures-menageres/exports/json?limit=-1&pretty=false&timezone=Europe%2FParis']
    },

    verre: {
        gSheetIndex: 'solution4 verre',
        cleanData: (data) =>
            data.map((row, i) => {
                return {
                    point: normalizeGeoPoint2d(row.geo_point_2d),
                    location: row.localisation ? capitalizeString(row.localisation) : null,
                    locationDetails: row.comp_loc ? capitalizeString(row.comp_loc) : null,
                    accessDisabled: row.pmr === 'Oui' ? true : false
                }
            }),
        smallIcon: verreSmallIcon,
        bigIcon: verreBigIcon,
        solutionIcon: verreSolutionIcon,
        dataUrls: ['https://data.issy.com/api/v2/catalog/datasets/les-points-dapport-volontaire-du-verre/exports/json?limit=-1&pretty=false&timezone=Europe%2FParis']
    },

    textile: {
        gSheetIndex: 'solution5 textile',
        cleanData: (data) =>
            data.map((row, i) => {
                return {
                    point: normalizeGeoPoint2d(row.geo_point_2d),
                    location: row.localisation ? capitalizeString(row.localisation) : null,
                    locationDetails: row.comp_loc ? capitalizeString(row.comp_loc) : null,
                }
            }),
        smallIcon: vetementSmallIcon,
        bigIcon: vetementBigIcon,
        solutionIcon: vetementSolutionIcon,
        dataUrls: ['https://data.issy.com/api/v2/catalog/datasets/les-points-dapport-volontaire-textile/exports/json?limit=-1&pretty=false&timezone=Europe%2FParis']
    },

    decheterie: {
        gSheetIndex: 'solution6 decheterie',
        cleanData: (dataFixes, dataMobile) => {
            const dataFormatter = (mobileOrFixed) => (row, i) => {
                return {
                    point: normalizeGeoPoint2d(row.geo_point_2d),
                    name: row.nom,
                    access: row.modalite_acces,
                    openingTimes: row.horaire,
                    volumeAllowed: row.volume,
                    mobileOrFixed, // 'fixe' or 'mobile'
                }
            }
            return dataFixes
                .map(dataFormatter('fixe')).concat(
                    dataMobile.map(dataFormatter('mobile'))
                )
        },
        smallIcon: decheterieSmallIcon,
        bigIcon: decheterieBigIcon,
        solutionIcon: decheterieSolutionIcon,
        dataUrls: [
            'https://data.issy.com/api/v2/catalog/datasets/decheteries-fixes/exports/json?limit=-1&pretty=false&timezone=Europe%2FParis',
            'https://data.issy.com/api/v2/catalog/datasets/decheteries-mobiles/exports/json?limit=-1&pretty=false&timezone=Europe%2FParis',
        ]
    },

    sapin: {
        gSheetIndex: 'solution7 sapin',
        cleanData: (data) =>
            data.map((row, i) => {
                return {
                    point: normalizeGeoPoint2d(row.geo_point_2d),
                    location: row.localisation ? capitalizeString(row.localisation) : null,
                    locationDetails: row.comp_loc ? capitalizeString(row.comp_loc) : null,
                }
            }),
        smallIcon: sapinSmallIcon,
        bigIcon: sapinBigIcon,
        solutionIcon: sapinSolutionIcon,
        dataUrls: ['https://data.issy.com/api/v2/catalog/datasets/les-enclos-a-sapins/exports/json?limit=-1&pretty=false&timezone=Europe%2FParis']
    },

    electromenager: {
        gSheetIndex: 'solution8 electromenager',
        cleanData: (data) => {
            const seenPoints = []
            return data.map((row, i) => {
                return {
                    point: normalizeGeoPoint2d(row.geolocalisation),
                }
            // Remove duplicates
            }).filter(datum => {
                if (seenPoints.some(seenPoint => 
                    seenPoint[0] === datum.point[0] 
                    && seenPoint[1] === datum.point[1])) {
                    return false
                } else {
                    seenPoints.push(datum.point)
                    return true
                }
            })
        },
        smallIcon: electromenagerSmallIcon,
        bigIcon: electromenagerBigIcon,
        solutionIcon: electromenagerSolutionIcon,
        dataUrls: ['https://data.issy.com/api/v2/catalog/datasets/suivi-collecte-des-d3e/exports/json?limit=-1&pretty=false&timezone=Europe%2FParis']
    },

    dangereux: {
        gSheetIndex: 'solution9 dangereux',
        cleanData: (data) =>
            data.map((row, i) => {
                return {
                    point: normalizeGeoPoint2d(row.geo_point_2d),
                    location: row.localisation ? capitalizeString(row.localisation) : null,
                    locationDetails: row.comp_loc ? capitalizeString(row.comp_loc) : null,
                    openingTimes: row.horaire,
                }
            }),
        smallIcon: toxiqueSmallIcon,
        bigIcon: toxiqueBigIcon,
        solutionIcon: toxiqueSolutionIcon,
        dataUrls: ['https://data.issy.com/api/v2/catalog/datasets/points-de-collecte-des-dechets-toxiques/exports/json?limit=-1&pretty=false&timezone=Europe%2FParis']
    },

    objets: {
        gSheetIndex: 'objets',
        cleanData: (rawData) => {
            return rawData.map((row) => {
                return {
                    name: row.gsx$objet.$t,
                    solution1: row.gsx$solution1.$t,
                    solution2: row.gsx$solution2.$t,
                }
            })
        },
        bigIcon: objetsBigIcon,
    },

    solution: {
        gSheetIndex: 'solution',
        cleanData: (rawData) => {
            return rawData.map((row) => {
                return {
                    title: row.gsx$solutiontitre.$t,
                    name: row.gsx$solution.$t,
                    longName: row.gsx$solutionlongue.$t,
                    textIntro: row.gsx$pagetexteintro.$t,
                    mapLocateFor: row.gsx$locate.$t,
                    didYouKnow: row.gsx$pagetextesaviezvous.$t,
                    urlMoreInfo: row.gsx$urlplusinfo.$t,
                    urlSeeData: row.gsx$urlvoirdonnees.$t,
                    callToAction: row.gsx$calltoaction.$t,
                    boxText:  row.gsx$cartouchetexte.$t,
                }
            })
        },
    },
}

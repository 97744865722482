import React from 'react'
import BEMHelper from 'react-bem-helper'
import { withSizes } from 'react-sizes'
import { FooterCredits } from 'issy-components'

import Header from '../Header'
import SolutionIcon from './SolutionIcon'
import './Home.css'
import './Home-animations.css'
import { withAppData } from '../hoc'
// import FooterCredits from './FooterCredits'
import Footer from '../Footer/Footer'
import config from '../config'
import PAGE_CONFIGS from '../page-configs'
import UnderlinedWord from '../UnderlinedWord'


const classes = new BEMHelper('Home')

class Home extends React.Component {

    constructor(props) {
        super(props)
        this.state = { mounted: false }
    }

    componentDidMount() {
        setTimeout(() => this.setState({ mounted: true }), 50)
    }

    render() {
        const solutionsElems = this.props.appData.solution.map((solutionData) => {
            return (
                <SolutionIcon 
                    title={solutionData.title} 
                    imgSrc={PAGE_CONFIGS[solutionData.name].smallIcon}
                    url={`${config.ROOT_URL}/${solutionData.name}`}
                    key={solutionData.name}
                />
            )
        })

        return (
            <div 
                { ...classes(
                    null, 
                    [
                        this.state.mounted ? 'animation-start': null,
                        this.props.igBigScreenHeight ? 'such-height': 'normal-height'
                    ]
                )}
            >
                <Header />
                <div { ...classes('scroll-area') }>
                    <h1> Je <UnderlinedWord text={'veux'} /> ...</h1>
                    <div { ...classes('solutions') }>
                        { solutionsElems }
                    </div>
                    <Footer>
                        <FooterCredits />
                    </Footer>
                </div>
            </div>
        )
    }
}


const HomeWithSizes = withSizes(({ height }) => {
    // Horrible hack to fix layout on big screen heights
    const home = document.querySelector('.Home__scroll-area')
    if (!home) {
        return {}
    }
    const pageTopPadding = Math.max(0, (height - home.scrollHeight) / 2)
    return {
        igBigScreenHeight: !!pageTopPadding,
    } 
})(Home)
export default withAppData(HomeWithSizes, ['solution', 'objets'])

import React from 'react'
import leaflet from 'leaflet'
import BEMHelper from 'react-bem-helper'
import { connect } from 'react-redux'
import isEqual from 'lodash.isequal'

import { multiPolygonListBounds, pointListBounds } from '../utils'
import config from '../config'
import Map from './Map'
import { mapSetSelectedShape, mapResetSuccess } from '../redux/actions'
import { getMostRecentUserPosition } from '../redux/selectors'


const classes = new BEMHelper('MapShapes')

class MapShapes extends React.Component {

    constructor(props) {
        super(props)
        this.leafletMap = null
        this.leafletPolygons = null
        this.state = {
            overShapeIndex: null
        }
    }

    onLeafletMapCreated = (leafletMap) => {
        this.leafletMap = leafletMap
        this.updatePolygons()
    }

    componentDidUpdate(prevProps) {
        if (this.props.selectedShapeIndex !== prevProps.selectedShapeIndex) {
            this.updatePolygonStyles()
        }

        if (this.props.shapes !== prevProps.shapes) {
            this.updatePolygons()
        }

        if ((this.props.resetRequested && !prevProps.resetRequested) 
            || !isEqual(this.props.userPosition, prevProps.userPosition)) {
            this.resetMapPositionZoom()
        }
    }

    resetMapPositionZoom() {
        const multiPolygons = this.props.shapes.map((shape) => shape.polygons)
        let bounds = multiPolygonListBounds(multiPolygons)
        if (this.props.userPosition) {
            bounds = pointListBounds([...bounds, this.props.userPosition])
        }
        this.leafletMap.fitBounds(bounds, { padding: config.MAP_FIT_BOUNDS_PADDING })
        this.props.mapResetSuccess()
    }

    updatePolygons() {
        if (!this.props.shapes || this.leafletPolygons !== null || !this.leafletMap) {
            return
        }

        this.leafletPolygons = this.props.shapes.map((shape, i) => {
            const onClick = () => this.props.mapSetSelectedShape(i)
            const onMouseOver = () => {
                this.setState({ overShapeIndex: i })
                this.updatePolygonStyles()
            }
            const onMouseOut = () => {
                if (this.state.overShapeIndex === i) {
                    this.setState({ overShapeIndex: null })
                    this.updatePolygonStyles()
                }
            }
            const leafletPolygon = leaflet.polygon(shape.polygons).addTo(this.leafletMap)
            leafletPolygon.on('click', onClick)
            leafletPolygon.on('mouseover', onMouseOver)
            leafletPolygon.on('mouseout', onMouseOut)
            return leafletPolygon
        })

        this.resetMapPositionZoom()
        this.updatePolygonStyles()
    }

    updatePolygonStyles() {
        if (!this.leafletPolygons || !this.leafletMap) {
            return
        }

        this.leafletPolygons.forEach((leafletPolygon, i) => {
            let style = config.MAP_SECTOR_BASE_STYLE
            if (this.props.selectedShapeIndex === i) {
                style = {
                    ...style,
                    ...config.MAP_SECTOR_SELECTED_STYLE
                }
            } else if (this.state.overShapeIndex === i) {
                style = {
                    ...style,
                    ...config.MAP_SECTOR_OVER_STYLE
                }
            }
            leafletPolygon.setStyle(style)
        })
    }

    render() {
        return (
            <div { ...classes() }>
                <Map {...this.props} onLeafletMapCreated={this.onLeafletMapCreated} />
            </div>
        );
    }
}
    
export default connect(
    (state) => ({ 
        resetRequested: state.map.resetRequested,
        userPosition: getMostRecentUserPosition(state)
    }),
    { mapSetSelectedShape, mapResetSuccess }
)(MapShapes)
import React from 'react'
import BEMHelper from 'react-bem-helper';
import { connect } from 'react-redux'

import { mapZoomMinus, mapZoomPlus, mapResetRequested } from '../redux/actions'
import './MapZoomControls.css';
import imgMinus from '../assets/_svg/_ui-interface/_map/_controler-map/icon-zoom_minus-white.svg'
import imgPlus from '../assets/_svg/_ui-interface/_map/_controler-map/icon-zoom_plus-white.svg'
import imgCenter from '../assets/_svg/_ui-interface/_map/_controler-map/icon-zoom_reset-white.svg'

const classes = new BEMHelper('MapZoomControls')


class MapZoomControls extends React.Component {
    
    onMinusClick = () => this.props.mapZoomMinus()
    onPlusClick = () => this.props.mapZoomPlus()
    onResetClick = () => this.props.mapResetRequested()

    render() {
        return (
            <div {...classes()}>
                <img {...classes('center', null, 'button--blue')} src={imgCenter} onClick={this.onResetClick} alt="re-centrer" />
                <div {...classes('plus_minus')}>
                    <img {...classes('minus', null, 'button--blue')} src={imgMinus} onClick={this.onMinusClick} alt="zoom" />
                    <img {...classes('plus', null, 'button--blue')} src={imgPlus} onClick={this.onPlusClick} alt="dezoom" />
                </div>
            </div>
        )
    }
}

export default connect(null, { 
    mapZoomPlus, mapZoomMinus, mapResetRequested 
})(MapZoomControls)
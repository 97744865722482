import React from 'react'
import BEMHelper from 'react-bem-helper'
import { connect } from 'react-redux'

import './MapLocalizationTools.css'
import { ReactComponent as LocationImg } from '../assets/_svg/_ui-interface/_map/icon-geolocation-on-white.svg'
import LocalizeMe from '../LocalizeMe'
import RawHtml from '../RawHtml'
import MapAddressSearch from './MapAddressSearch'

const classes = new BEMHelper('MapLocalizationTools')

class MapLocalizationTools extends React.Component {

    render() {
        return (
            <div { ...classes() }>
                <div>
                    <div>
                        <b>→</b> Localisez-vous pour afficher
                    </div>
                    <div {...classes('les-date-ou-les-horaires')}>
                        <RawHtml html={this.props.locateForText} />
                    </div>
                </div>

                <div { ...classes('controls') }>
                    <div { ...classes(
                        'localize-yourself', 
                        this.props.isLocalized ? 'localized' : null
                    ) }>
                        <label>Localisez-vous</label>
                        <LocalizeMe>
                            <button className="button button--blue">
                                <div { ...classes('localize-yourself-button-container') }>
                                    <span>
                                        {
                                            this.props.isLocalized ? 'Localisé': 'Localisez-moi'
                                        }
                                    </span>
                                    <LocationImg />
                                </div>
                            </button>
                        </LocalizeMe>
                    </div>
                    <MapAddressSearch />
                </div>
            </div>
        )
    }
}

export default connect(
    (state) => ({
        isLocalized: !!state.geoLocation.point
    })
)(MapLocalizationTools)
    
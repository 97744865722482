import React from 'react'
import BEMHelper from 'react-bem-helper'

import './UnderlinedWord.css'

const classes = new BEMHelper('UnderlinedWord')


class UnderlinedWord extends React.Component {

    constructor(props) {
        super(props)
        this.state = { mounted: false }
    }

    componentDidMount() {
        setTimeout(() => this.setState({ mounted: true }), 50)
    }

    render() {
        return (
            <span {...classes()}>
                <span {...classes('shaper')}>{this.props.text}</span>
                <span {...classes('text')}>{this.props.text}</span>
                <span {...classes('line', this.state.mounted ? 'animation-start': null)}></span>
            </span>
        )
    }
}

export default UnderlinedWord
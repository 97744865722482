export const geoLocationRequest = () => 
    ({ 
        type: 'geoLocationRequest' 
    })

export const geoLocationSuccess = (point) => 
    ({ 
        type: 'geoLocationSuccess',
        payload: { point }
    })

export const geoLocationError = (error) => 
    ({ 
        type: 'geoLocationError',
        error
    })

export const appDataFetchRequest = (sheetNames) => 
    ({
        type: 'appDataFetchRequest',
        payload: { sheetNames }
    })

export const appDataFetchSuccess = (sheetName, data) => 
    ({
        type: 'appDataFetchSuccess',
        payload: { sheetName, data }
    })

export const appDataFetchError = (sheetName, error) => 
    ({
        type: 'appDataFetchError',
        payload: { sheetName },
        error
    })

export const addressQueryRequest = (query) => 
    ({
        type: 'addressQueryRequest',
        payload: { query }
    })

export const addressQuerySuccess = (query, results) => 
    ({
        type: 'addressQuerySuccess',
        payload: { query, results }
    })

export const addressQueryError = (query, error) => 
    ({
        type: 'addressQueryError',
        payload: { query },
        error
    })

export const addressQuerySelectResult = (point, address) => 
    ({
        type: 'addressQuerySelectResult',
        payload: { point, address },
    })

export const addressQueryReset = () => 
    ({
        type: 'addressQueryReset',
    })

export const mapZoomPlus = () => 
    ({
        type: 'mapZoomPlus'
    })

export const mapZoomMinus = () => 
    ({
        type: 'mapZoomMinus'
    })

export const mapSetZoom = (zoom) => 
    ({
        type: 'mapSetZoom',
        payload: { zoom }
    })

export const mapResetRequested = () =>
    ({
        type: 'mapResetRequested'
    })

export const mapResetSuccess = () =>
    ({
        type: 'mapResetSuccess'
    })

export const mapSetShapes = (shapes) =>
    ({
        type: 'mapSetShapes',
        payload: { shapes }
    })

export const mapResetShapes = () =>
    ({
        type: 'mapResetShapes',
        payload: {}
    })

export const mapSetSelectedShape = (selectedShapeIndex) =>
    ({
        type: 'mapSetSelectedShape',
        payload: { selectedShapeIndex }
    })

export const mapSetPins = (pins) =>
    ({
        type: 'mapSetPins',
        payload: { pins }
    })

export const mapResetPins = () =>
    ({
        type: 'mapResetPins',
        payload: {}
    })

export const mapSetSelectedPin = (selectedPinIndex) =>
    ({
        type: 'mapSetSelectedPin',
        payload: { selectedPinIndex }
    })
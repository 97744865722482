import { takeEvery, all } from 'redux-saga/effects'

import { geoLocationRequestSaga } from './geoLocation'
import { appDataFetchRequestSaga } from './appData'
import { addressQueryRequestSaga } from './addressQuery'
import { cookiesSaga, historySaga } from 'issy-components'

function* handleErrors() {
    yield takeEvery('*', function *errorHandler(action) {
        if (action.error) {
            console.error(action.error)
        }
        yield
    })
}

export default function* rootSaga() {
    yield all([
        geoLocationRequestSaga(),
        appDataFetchRequestSaga(),
        addressQueryRequestSaga(),
        cookiesSaga(),
        historySaga(),
        handleErrors(),
    ])
}
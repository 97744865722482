import React from 'react'
import { connect } from 'react-redux'
import BEMHelper from 'react-bem-helper'

import { geoLocationRequest } from './redux/actions'

const classes = new BEMHelper('LocalizeMe')


class LocalizeMe extends React.Component {

    onClick = () => {
        this.props.geoLocationRequest()
    }

    render() {
        return (
            <div {...classes} onClick={this.onClick}>
                {this.props.children}
            </div>
        )
    }
}

export default connect(
    null, 
    {
        geoLocationRequest
    },
)(LocalizeMe)
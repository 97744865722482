import React from 'react'
import leaflet from 'leaflet'

import MapPins from './MapPins'
import { withPinsMap, withAppData } from '../hoc'
import SolutionPage from './SolutionPage'
import { classes as mapInfoBoxClasses } from './MapInfoBox'

import iconSelectedPin from '../assets/_svg/_ui-interface/_map/icon-map_marker-big-off-brown.svg'
import iconPin from '../assets/_svg/_ui-interface/_map/icon-map_marker-big-off-green.svg'
import config from '../config'


export const TAB_NAME = 'electromenager'


class SolutionElectromenager extends React.Component {

    renderOverlayContent() {
        if (!this.props.mapSelectedPin) {
            return null
        }

        return (
            <div>
                <div {...mapInfoBoxClasses('info1')}>
                    Voir les jours et horaires des collectes sur
                </div>
                <a 
                    className="button button--blue"
                    href={config.QUE_FAIRE_A_ISSY_URL}
                    target="_blank"
                    rel="noopener noreferrer"
                >Que Faire à Issy</a>
            </div>
        )
    }

    getPins() {
        if (!this.props.mapPins) {
            return
        }
        return this.props.mapPins.map((pin) => ({
            ...pin,
            icon: leaflet.icon({
                iconUrl: iconPin,
            }),
            selectedIcon: leaflet.icon({
                iconUrl: iconSelectedPin,
            })
        }))
    }

    render() {
        return (
            <SolutionPage
                solutionData={this.props.solutionData}
            >
                <MapPins
                    solutionData={this.props.solutionData}
                    pins={this.getPins()} 
                    selectedPinIndex={this.props.mapSelectedPinIndex}
                    infoBoxContent={this.renderOverlayContent()}
                    canInfoBoxBeCollapsed={false}
                />
            </SolutionPage>
        );
    }
}
    
export default withAppData(
    withPinsMap(SolutionElectromenager, TAB_NAME),
    [TAB_NAME]
)
import React from 'react'
import BEMHelper from 'react-bem-helper';
import { connect } from 'react-redux'
import debounce from 'lodash.debounce'

import { addressQueryRequest, addressQuerySelectResult, 
    addressQueryReset } from '../redux/actions'
import SearchField from '../SearchField'
import './MapAddressSearch.css'
import config from '../config'

const classes = new BEMHelper('MapAddressSearch')


class MapAddressSearch extends React.Component {

    constructor(props) {
        super(props)
        this.state = { query: null }
    }

    componentDidMount() {
        this.debouncedQuery = debounce(() => {
            this.props.addressQueryRequest(this.state.query)
        }, config.AUTOCOMPLETE_DEBOUNCE_TIME)
    }

    componentWillUnmount() {
        this.debouncedQuery && this.debouncedQuery.cancel()
    }

    renderSearchResults() {
        if (!this.props.searchResults) {
            return null
        }
        const searchResultsElems = this.props.searchResults
            .slice(0, config.AUTOCOMPLETE_ADDRESS_MAX_RESULTS)
            .map(({ address, point }) => {
                const onClick = () => {
                    this.props.addressQuerySelectResult(point, address)
                }
                return (
                    <div 
                        {...classes('results-item')}
                        key={address}
                        onClick={onClick}
                    >
                        {address}
                    </div>
                )
            })
        return (
            <div {...classes('results')}>
                {searchResultsElems}
            </div>
        )
    }

    onQueryChange = (query) => {
        this.setState({ query })
        if (query.length) {
            this.debouncedQuery()
        // When address field is emptied we reset the state
        } else {
            this.props.addressQueryReset()
        }
    }

    render() {
        return (
            <div { ...classes() }>
                <label>Ou précisez votre rue, adresse, ...</label>
                <SearchField 
                    onInputChange={this.onQueryChange}
                    initialValue={this.props.address || ''}
                    placeholder="Rue, adresse..."
                />
                {this.renderSearchResults()}
            </div>
        )
    }
}

export default connect(
    (state) => ({
        searchResults: state.addressQuery.results,
        address: state.addressQuery.address,
    }), 
    { 
        addressQueryRequest, 
        addressQueryReset,
        addressQuerySelectResult 
    }
)(MapAddressSearch)
import React from 'react'
import BEMHelper from 'react-bem-helper'

import './FooterContactBar.css'
import './shared.css'
import unDouteImg from '../assets/_svg/_icons/_others/icon-phonecall.svg'
import UnderlinedWord from '../UnderlinedWord'


const classes = new BEMHelper('FooterContactBar')

function FooterContactBar(props) {

    return (
        <div {...classes(null, null, props.className)}>
            <img src={unDouteImg} alt="vous avez un doute?" />
            <div {...classes('vous-avez-un-doute-container')}>
                <div>
                    <h2>Un <UnderlinedWord text={'doute'}/> ?</h2>
                    <div {...classes('vous-avez-un-doute')} >
                        Vous avez un doute sur la manière de recycler un objet, contactez le
                    </div>
                </div>
                <div {...classes('phone-container')}>
                    <div {...classes('phone')}>
                        0 800 10 10 21
                    </div>
                    <div {...classes('appel-gratuit')}>appel gratuit depuis un poste fixe</div>
                </div>
            </div>
        </div>
    )
}

export default FooterContactBar
import React from 'react'
import BEMHelper from 'react-bem-helper'

import { ReactComponent as ExternalLinkImage } from '../assets/_svg/_ui-interface/_map/icon-external_link-dark-ocean.svg'
import './MapFooter.css'
import MapZoomControls from './MapZoomControls'

const classes = new BEMHelper('MapFooter')


function MapFooter (props) {
    return (
        <div {...classes()}>
            <a 
                {...classes(
                    'see-data', 
                    null, 
                    ['button', 'button--white']
                )}
                href={ props.urlSeeData } 
                target="_blank"
                rel="noopener noreferrer"
            >
                <span>Voir les données</span>
                <ExternalLinkImage />
            </a>
            <MapZoomControls 
                initialZoom={ props.initialZoom }
            />
        </div>
    )
} 

export default MapFooter
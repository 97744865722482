import React, { Fragment } from 'react'
import BEMHelper from 'react-bem-helper'

import './Footer.css'
import FooterSearchBar from './FooterSearchBar'
import FooterContactBar from './FooterContactBar'

const classes = new BEMHelper('Footer')


function Footer(props) {
    return (
        <Fragment>
            <div { ...classes('search-contact', 'visible') }>
                <FooterSearchBar />
                <FooterContactBar />
            </div>
            {props.children}
            <div { ...classes('search-contact', 'hidden') }>
                <FooterSearchBar />
                <FooterContactBar />
            </div>
        </Fragment>
    )
}

export default Footer
import React from 'react'
import BEMHelper from 'react-bem-helper'
import { connect } from 'react-redux'

import plusImg from '../assets/_svg/_ui-interface/_map/_controler-map/icon-zoom_plus-white.svg'
import minusImg from '../assets/_svg/_ui-interface/_map/_controler-map/icon-zoom_minus-white.svg'

import infoImg from '../assets/_svg/_ui-interface/_map/_tooltip-map/icon-tooltip-info-dark.svg'


import './MapInfoBox.css'
import { isSectorUnknown } from '../redux/selectors'

export const classes = new BEMHelper('MapInfoBox')


class MapInfoBox extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            expanded: true
        }
    }

    renderExpandCollapseButton() {
        if (this.props.canBeCollapsed === false) {
            return
        }
        return (
            <img 
                src={this.state.expanded ? minusImg : plusImg}
                onClick={this.onExpandCollapseClicked} 
                {...classes('expand-collapse')}
                alt="ouvrir ou fermer"
            />
        )
    }

    renderInfoBox() {

        if (this.props.isSectorUnknown) {
            return (
                <div {...classes('info-box', 'error')}>
                    {this.renderExpandCollapseButton()}
                    <div {...classes('info1')} >
                        Impossible de vous localiser dans un de ces secteurs
                    </div>
                    <div {...classes('info2') }>
                        <img 
                            {...classes('info-icon')} 
                            src={infoImg} 
                            alt=""
                        />
                        Vous pouvez cliquer sur un secteur ou essayer avec une adresse
                    </div>
                </div>
            )
        
        } else if (this.props.infoBoxContent) {
            return (
                <div {...classes('info-box')}>
                    {this.renderExpandCollapseButton()}
                    {this.props.infoBoxContent}
                </div>
            )
        }
    }

    onExpandCollapseClicked = () => {
        this.setState({ expanded: !this.state.expanded })
    }

    render() {
        return (
            <div {...classes(null, this.state.expanded ? 'expanded': 'collapsed')}>
                {this.renderInfoBox()}
            </div>
        )
    }
}

export default connect(
    (state) => ({
        isSectorUnknown: isSectorUnknown(state),
    }),
)(MapInfoBox)
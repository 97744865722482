import React from 'react'
import BEMHelper from 'react-bem-helper'
import { withRouter } from 'react-router-dom'

import './SolutionBox.css'
import PAGE_CONFIGS from './page-configs'
import config from './config'

const classes = new BEMHelper('SolutionBox')

function SolutionBox(props) {
    const onClick = () => props.history.push(`${config.ROOT_URL}/${props.solutionName}`)
    return (
        <div {...classes('')} onClick={onClick}>
            <div {...classes('header')}>
                <div>
                    <div {...classes('surtitle')}>{props.surtitle}</div>
                    <div {...classes('title')}>{props.title}</div>
                    <div>{props.subtitle}</div>
                </div>
                <img 
                    src={PAGE_CONFIGS[props.solutionName].solutionIcon} 
                    alt={classes('title')}
                />
            </div>
            <div {...classes('body')}>
                {props.children}
            </div>
            <div {...classes('footer')}>
                {props.footerText} >
            </div>
        </div>
    )
}

export default withRouter(SolutionBox)
import React from 'react'
import BEMHelper from 'react-bem-helper'
import { useHistory } from 'react-router-dom'
import { withSizes } from 'react-sizes'

import './SolutionIcon.css'
import config from '../config'

const classes = new BEMHelper('SolutionIcon')

function SolutionIcon({ title, imgSrc, url, isDesktop }) {
    const history = useHistory()
    const onClick = () => history.push(url)
    return (
        <div onClick={onClick} { ...classes(null, isDesktop ? 'desktop' : null) }>
            <div { ...classes('image-container') }>
                <svg viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
                    <circle cx="50" cy="50" r="50"/>
                </svg>
                <svg { ...classes('animation') } viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
                    <circle cx="50" cy="50" r="50"/>
                </svg>
                <img { ...classes('image') } src={imgSrc} alt={title} />
            </div>
            <div { ...classes('title') }>{title}</div>
        </div>
    )
}
    
export default withSizes(({ width }) => {
    return {
        isDesktop: width > config.TABLET_WIDTH_UPPER
    }
})(SolutionIcon)
    
import React from 'react'
import { connect } from 'react-redux'
import leaflet from 'leaflet'
import BEMHelper from 'react-bem-helper'
import isEqual from 'lodash.isequal'

import { pointListBounds } from '../utils'
import Map from './Map'
import { mapSetSelectedPin, mapResetSuccess } from '../redux/actions'
import overPinImage from '../assets/_svg/_ui-interface/_map/icon-map_marker-big-actif.svg'
import { getMostRecentUserPosition } from '../redux/selectors'
import config from '../config'


const overPinIcon = leaflet.icon({ iconUrl: overPinImage })
const classes = new BEMHelper('MapPins')

class MapPins extends React.Component {

    constructor(props) {
        super(props)
        this.leafletMap = null
        this.leafletPins = null
        this.state = {
            overPinIndex: null
        }
    }

    onLeafletMapCreated = (leafletMap) => {
        this.leafletMap = leafletMap
        this.updatePins()
    }

    componentDidUpdate(prevProps) {
        if (this.props.selectedPinIndex !== prevProps.selectedPinIndex) {
            this.updateSelectedPin()
        }

        if (this.props.pins !== prevProps.pins) {
            this.updatePins()
        }

        if ((this.props.resetRequested && !prevProps.resetRequested)
            || !isEqual(this.props.userPosition, prevProps.userPosition)) {
            this.resetMapPositionZoom()
        }
    }

    updateSelectedPin() {
        if (!this.leafletMap) {
            return 
        }
        if (this.props.selectedPinIndex !== null) {
            this.leafletMap.panTo(this.props.pins[this.props.selectedPinIndex].point)
        }
        this.updatePinStyles()
    }

    updatePins() {
        if (!this.props.pins || this.leafletPins !== null || !this.leafletMap) {
            return
        }

        this.leafletPins = this.props.pins.map((pin, i) => {
            const onClick = (a) => {
                this.leafletMap.panTo(pin.point)
                this.props.mapSetSelectedPin(i)
            }
            const onMouseOver = () => {
                this.setState({ overPinIndex: i })
                this.updatePinStyles()
            }
            const onMouseOut = () => {
                if (this.state.overPinIndex === i) {
                    this.setState({ overPinIndex: null })
                    this.updatePinStyles()
                }
            }
            const leafletPin = leaflet.marker(pin.point)
                .addTo(this.leafletMap)
            leafletPin.on('click', onClick)
            leafletPin.on('mouseover', onMouseOver)
            leafletPin.on('mouseout', onMouseOut)
            return leafletPin
        })

        this.resetMapPositionZoom()
        this.updatePinStyles()
    }

    updatePinStyles() {
        if (!this.leafletPins || !this.leafletMap) {
            return
        }

        this.leafletPins.forEach((leafletPin, i) => {
            const pin = this.props.pins[i]
            if (this.props.selectedPinIndex === i) {
                leafletPin.setIcon(pin.selectedIcon)
            } else if (this.state.overPinIndex === i) {
                leafletPin.setIcon(overPinIcon)
            } else {
                leafletPin.setIcon(pin.icon)
            }
        })
    }

    resetMapPositionZoom() {
        const points = this.props.pins.map((pin) => pin.point)
        let bounds = pointListBounds(points)
        if (this.props.userPosition) {
            bounds = pointListBounds([...bounds, this.props.userPosition])
        }
        this.leafletMap.fitBounds(bounds, { padding: config.MAP_FIT_BOUNDS_PADDING })
        this.props.mapResetSuccess()
    }
    
    render() {
        return (
            <div { ...classes() }>
                <Map {...this.props} onLeafletMapCreated={this.onLeafletMapCreated} />
            </div>
        );
    }
}
    
export default connect(
    (state) => ({ 
        resetRequested: state.map.resetRequested,
        userPosition: getMostRecentUserPosition(state),
    }), 
    { mapSetSelectedPin, mapResetSuccess }
)(MapPins)